import React from "react";
import { useGetIsLoggedIn } from "@elrondnetwork/dapp-core/hooks";
import { logout } from "@elrondnetwork/dapp-core/utils";
import { Navbar as BsNavbar, NavItem, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { dAppName } from "config";
import { routeNames } from "routes";
import { ReactComponent as ElrondLogo } from "./../../../assets/img/elrond.svg";

export const Navbar = () => {
	const isLoggedIn = useGetIsLoggedIn();

	const handleLogout = () => {
		logout(`${window.location.origin}/unlock`);
	};

	return (
		<BsNavbar
			className="border-bottom px-4 py-3"
			style={{ backgroundColor: "rgba(240, 151, 59, 0.9)" }}
		>
			<div className="container-fluid">
				<Link
					className="d-flex align-items-center navbar-brand mr-0"
					to={isLoggedIn ? routeNames.dashboard : routeNames.home}
				>
					<ElrondLogo className="elrond-logo" />
					<span className="dapp-name text-white">{dAppName}</span>
				</Link>

				<Nav className="ml-auto">
					{isLoggedIn && (
						<NavItem>
							<button className="btn btn-link" onClick={handleLogout}>
								Close
							</button>
						</NavItem>
					)}
				</Nav>
			</div>
		</BsNavbar>
	);
};
